.media-section{
    margin-top: 100px;
    .media-grid{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 60px;
        margin-top: 50px;
        .item{
            display: flex;
            align-items: center;
            margin-left: 5vw;
            .container{
                width: 80%;
            }
            iframe{
                &:nth-child(2){
                    margin-top: 60px;
                }
                display: block;
                width: 100%;
                min-height: 350px;
            }
        }
        .media-img{
            float: right;
            width: 100%;
        }
    }
}