@media screen and (max-width: 1100px){

    /* CONTACT */ 
    .contact-section{
        background-position-x: 25%;
    }
       /* Calendar Section */
       .calendar-section .calendar-grid  .modal-content {
        width: 80vw;
        margin: 25% auto;
    }
    /* GALLERY */ 
    .SRLImage{
        margin-top: -15px;
    }
    .SRLCaptionContainer{
        margin-top: 25px;
    }

}
@media screen and (max-width: 1000px){
    /* BIO */ 
    .bio-f{
        background-position: 20%;
    }
    .trigger .textbio .inside-bio .bio-text{
        column-count: 1;
    }

}
@media screen and (max-width: 900px){
    /* MEDIA */ 
    .media-section .media-grid{
        grid-template-columns: repeat(1, 1fr);
    }
    .media-section .media-grid .item .container{
        margin: 0 auto;
    }

    /* SOCIAL */ 
    .social-section .logo-section{
        width: 90vw;
        a{
            text-align: center;
        }
    }
    /* CALENDAR */ 
    .calendar-section .calendar-grid .calendar-inner-grid{
        grid-template-columns: 100%;
        gap: 30px;
    }
    .calendar-section .calendar-grid .calendar-inner-grid .item:nth-child(2) h2{
        font-size: 30px;
    }
    .calendar-section .calendar-grid .calendar-inner-grid .item:nth-child(2) p{
        font-size: 16px;
    }
}
@media screen and (max-width: 800px){
    /* HERO */ 
    .hero-section .hero-image{
        background-position-x: 20% !important;
    }
    .hero-section .container-name{
        left: 5vw;
        width: 90vw;
    }

    /* CONTACT */ 
    .contact-section .container-contact{
        margin-top: 70%;
    }
    .contact-section{
        justify-content: flex-start;
        background:linear-gradient(100.86deg, #000000 0.81%, rgba(0, 0, 0, 0) 90.34%), url(../Assets/img/contact.jpg);
        background-size: cover;
        background-position-x: 20%;
        h2{
            text-align: left;
        }
    }
    .contact-section .container-contact{
        margin-left: 5vw
    }
    .calendar-section .calendar-grid .item .modal-content {
        h1 {
            font-size: 35px;
        }
        .sub {
            font-size: 15px;
        }
        .information-2 {
            font-size: 17px;
        }
    }
    /* FOOTER */ 
       footer {
        .footer-section {
            padding-bottom: 27px;
            .item {
                margin-top: 7px;
            }
            .item-3 {
                text-align: left;
            }
        }
    }

}

@media screen and (max-width: 700px){
    /* NAV */
    nav .nav-content .logo a h1{
        font-size: 6.1vw;
    } 
    nav .nav-content .logo a h2{
        font-size: 4.5vw;
    }
    nav .nav-content .burger svg{
        width: 12vw;
        height: auto;
    }
    .notscrolled nav .nav-content{
        margin: 30px 0px;
    }
 /* CALENDAR */ 
 .no-event-grid{
    grid-template-columns: repeat(1, 1fr);
    width: 85vw;
}
    /* HERO */ 
    .hero-section .container-name h2{
        font-size: 7.5vw;
    }
    .hero-section .container-name p{
        font-size: 5vw;
    }
    /* BIO */ 
    .normal-headline{
        font-size: 13vw;
    }
    /* SOCIAL */
    .social-section .normal-headline{
        font-size: 11.5vw;
    }
    /* GALLERY */ 
    .gallery-section .desktop-gallery .gallery-grid{
        width: 90vw;
    }
   
}


@media screen and (max-width: 600px){
    /* BIO */ 
    .no-webp{
    .bio-f{
        background: url(../Assets/img/bio-mobile.jpg);
        background-size: 100%;
        background-position-x: center;
        background-repeat: no-repeat;
    }
    }
    .webp{
        .bio-f{
            background: url(../Assets/img/bio-mobile.webp);
            background-size: 100%;
            background-position-x: center;
            background-repeat: no-repeat;
        }
    }
    .trigger .textbio .inside-bio .bio-text{
        font-size: 15px;
    }

    /* MEDIA */ 
    .media-section .media-grid .item iframe{
        min-height: 200px;
    }

    /* SOCIAL */ 
    .social-section .logo-section a svg{
        width: 17vw;
        height: auto;
        margin: 0 auto;
    }
}

@media screen and (max-width: 500px){

    /* CALENDAR */ 
    .calendar-section .calendar-grid .calendar-inner-grid .item:nth-child(2) h2{
        font-size: 23px;
    }
    .calendar-section .calendar-grid .calendar-inner-grid .item:nth-child(2) .view-more-btn{
        padding: 10px 0px;
        width: 95%;
        text-align: center;
    }
    .Collapsible .Collapsible__trigger{
        font-size: 30px;
    }

    /* GALLERY */ 
    .gallery-section .desktop-gallery .gallery-grid .sub-grid{
        grid-template-columns: repeat(1, 1fr);
    }
    /* CONTACT */ 
    .contact-section .container-contact{
        margin-top: 110%;
    }
     /* FOOTER */
     footer .footer-section {
        .item {
            text-align: center
        }
    }
     /* GALLERY */ 
     .css-1x4ynwp::before {
        font-size: 16px;
    }   
}
@media screen and (max-width: 350px){

    /* MEDIA */ 
    .media-section .media-grid .item iframe{
        min-height: 140px;
    }

    /* CONTACT */ 
    .contact-section{
        background-position-x: 28%;
    }

    .contact-section .contact-item .hover-box .center-link a{
        font-size: 6vw;
    }


}
