footer {
  background: #1a1a1a;
  width: 100vw;
  margin-right: 0;
  .footer-section {
      width: 85vw;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      padding: 20px 0px;
      font-family: 'Montserrat';
      .item {
          color: white;
          display: inline-block;
          font-size: 16px;
          position: relative;
          opacity: .7;
          a {
              color: white;
              display: inline-block;
              opacity: .7;
              &:hover {
                  text-decoration: none;
              }
              img {
                  width: auto;
                  height: 24px;
                  margin-bottom: -5px;
              }
          }
      }
      .item-2 {
          text-align: center;
      }
      .item-3 {
          text-align: right;
      }
  }
}
.margin-footer{
  margin-top: 120px;

}

@supports not (display: grid) {
  footer {
      .footer-section {
          .item-1,
          .item-2,
          .item-3 {
              display: flex;
              justify-content: center;
              margin-top: 8px;
          }
      }
  }
}