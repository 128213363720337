@font-face {
  font-family: 'Horn';
  src: url('fonts/HornBold.eot');
  src: url('fonts/HornBold.eot?#iefix') format('embedded-opentype'),
      url('fonts/HornBold.woff2') format('woff2'),
      url('fonts/HornBold.woff') format('woff'),
      url('fonts/HornBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}



*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    background: black;
    color: white;
}
.wrapper{
  width: 100%;
  //overflow-x: hidden;
}

h1, h2, h3, h4{
    font-family: 'Montserrat';
    letter-spacing: 0.08em;
    font-size: 90px;
    font-weight: 300;
    font-display: swap;
  
}

p, a{
    font-family: 'Montserrat', Arial;
    line-height: 1.7;
    font-weight: 400;
}


.fade{
    opacity: 0;
    transition: 1.3s ease;
    position: relative;
    transform: translateY(90px);


}

  .fade[data-emergence=hidden] {
    opacity: 0;
    transform: translateY(90px);
    .fade-headline-contact{
      &::before{
        transform: translateX(-50%) translateY(-50%) rotate(-45deg) scaleX(0);
    }
    }

  }
  .fade[data-emergence=visible] {
  opacity: 1;
  transform: translateY(0px);
  .fade-headline-contact{
    &::before{
      transform: translateX(-50%) translateY(-50%) rotate(-45deg) scaleX(1);
  }
  }
}

  #calendar, #media, #repertoire, #bio{
    height: 40px;
  }

  .CookieConsent{
    opacity: 0 ;
    animation: cookiefade 1.2s forwards 6s;
}

@keyframes cookiefade{
    from{
        opacity: 0 ;
    }
    to{
        opacity: 1 ;
    }
}


.normal-headline{
  font-size: 80px;
  position: relative;
  text-align: center;
  font-weight: 600;
}


.align-vertical{
  display: flex;
  align-items: center;
}

.fade-headline[data-emergence=hidden] {
  &::before{
    transform: translateX(-50%) translateY(-50%) rotate(-45deg) scaleX(0);
}

}
.fade-headline[data-emergence=visible] {
  &::before{
    transform: translateX(-50%) translateY(-50%) rotate(-45deg) scaleX(1);

  }
}


#about, #media, #gallery, #social{
  height: 100px;
}
