
.hero-section{
    width: 100vw;
    height: 100vh;
    position: relative;
    background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 23.95%);

    .hero-image{
        width: 100vw;
        height: 100vh;
        opacity: 0;
        background-position-x: center !important;
        background-size: cover !important;
        -webkit-background-size: cover !important;
        -webkit-transition: background 1s !important;
        -moz-transition: background 1s !important;
        -o-transition: background 1s !important;
        transition: background .5s !important;
    }
    .container-name{
        right: 5%;
        bottom: 20%;
        position: absolute;
        display: none;

        h2{
           font-weight: 600;
           font-size: 52px;
        }
        p{
            font-size: 28px;
        }
        svg{
            position: absolute;
            transform:  translate(0%, -100%);
            &:nth-child(2){
                path{
                    clip-path: circle(2.1% at 0 100%);
                }
            }
        }

            .loaded-text{
                .accent-1{
                animation: 2s filling ease-in-out forwards 1.5s;

                }
                .accent-2{
                animation: 2s filling ease-in-out forwards .3s;

                }
                path{
                    clip-path: circle(2.1% at 0 100%);
                }
                path:nth-child(1){
                animation: 2s filling ease-in-out forwards;
            }
            path:nth-child(2){
                animation: 2s filling ease-in-out forwards .2s;
            }
            path:nth-child(3){
                animation: 2s filling ease-in-out forwards .4s;
            }
            path:nth-child(4){
                animation: 2s filling ease-in-out forwards .5s;
            }
            path:nth-child(5){
                animation: 2s filling ease-in-out forwards .6s;
            }
            path:nth-child(6){
                animation: 2s filling ease-in-out forwards .7s;
            }
            path:nth-child(7){
                animation: 2s filling ease-in-out forwards .8s;
            }
            path:nth-child(8){
                animation: 2s filling ease-in-out forwards .9s;
            }
            path:nth-child(9){
                animation: 2s filling ease-in-out forwards 1s;
            }
            path:nth-child(10){
                animation: 2s filling ease-in-out forwards 1.1s;
            }
            path:nth-child(11){
                animation: 2s filling ease-in-out forwards 1.1s;
            }
            path:nth-child(12){
                animation: 2s filling ease-in-out forwards 1.2s;
            }
            path:nth-child(13){
                animation: 2s filling ease-in-out forwards 1.3s;
            }
            path:nth-child(14){
                animation: 2s filling ease-in-out forwards 1.4s;
            }
            path:nth-child(15){
                animation: 2s filling ease-in-out forwards 1.5s;
            }
            path:nth-child(16){
                animation: 2s filling ease-in-out forwards 1.6s;
            }
            path:nth-child(17){
                animation: 2s filling ease-in-out forwards .2s;
            }
            path:nth-child(18){
                animation: 2s filling ease-in-out forwards 1.2s;
            }
        }
    }
}

@keyframes filling{
    0%{
        clip-path: circle(2.1% at 0 100%);   }

    100%{
        clip-path: circle(70.7% at 50% 50%);   }
}
.no-webp{
    
.loaded-hero{
    background: linear-gradient(360deg, #000000 1.78%, rgba(0, 0, 0, 0) 14.17%), url(../Assets/img/hero.jpg);
    background-size: cover;
    background-position-x: right;
    animation: 1s bgFade ease-in-out forwards .5s;
}
}
.webp{
    .loaded-hero{
        background: linear-gradient(360deg, #000000 1.78%, rgba(0, 0, 0, 0) 14.17%), url(../Assets/img/hero.webp);
        background-size: cover;
        background-position-x: right;
        animation: 1s bgFade ease-in-out forwards .5s;
    }
}

#heroText{
    opacity: 0;
    margin-top: -40px;
}
.loaded-text{
    opacity: 1;
    animation: 1.2s heroTextFade forwards 1.4s;
    
}

@keyframes bgFade{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}


@keyframes heroTextFade{
    from{
        opacity: 0;
        margin-top: -40px
    }
    to{
        opacity: 1;
        margin-top: 0;
    }
}