nav{
    height: auto;
    position: fixed;
    width: 100vw;
    background: transparent;
    transition: background 2.5s;
    z-index: 9999999999999;
    transform: translateY(-100%);
    top: 0;
    .nav-content{
        position: relative;
        z-index: 99999999999;
        transition: margin .5s;
        margin: 45px 0px;
        .logo{
            display: inline-block;
            margin-left: 5vw;
            a{
                text-decoration: none;
                color: white;
                h1{
                    font-size: 45px;
                    line-height: 1;
                    font-weight: 600;
                }
                h2{
                    font-size: 25px;
                }
            }
        }
        .burger{
            position: absolute;
            right: 5vw;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            svg{
                width: 70px;
            }
            
            .line{
                transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
                stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
                transition: width .5s;
                fill: none;
                stroke: white;
                stroke-width: 3;
                transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
                  stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
                /* height: 2.5px;
                background: white;
                width: 50px; */
                &:not(:first-child){
                    margin-top: 11px;
                }
            }
            .line1 {
                stroke-dasharray: 60 207;
                stroke-width: 3;
              }
              .line2 {
                stroke-dasharray: 60 60;
                stroke-width: 3;
              }
              .line3 {
                stroke-dasharray: 60 207;
                stroke-width: 3;
              }
            .line-2{
                width: 30px;
            }
            .line-3{
                width: 45px;
            }
        }
    }
    .nav-links{
        opacity: 0;
        transition:  opacity 1s;
        transform: translateX(-100%);
        height: 0;
        margin: auto 0;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        ul{
        list-style: none;
        text-align: center;
   
        li{
            transition: transform .4s ease;
            margin-top: 20px;
            a{
                text-decoration: none;
                color: white; 
                font-family: 'Montserrat';
                font-size: 3.4vh;
                letter-spacing: 0.07em;
                margin-top: 40px;


            }
            &:hover{
                transform: translateX(5px);
            }
        }
        .language-switch{
            
            &:hover{
                transform: none;
            }
            a{
                font-size: 3vh !important;
                opacity: .4;
                cursor: pointer;
                padding: 15px;
                &:nth-child(1){
                    border-right: 1px solid rgba(255, 255, 255, .4);
                }
            }
            .active-lang{
                opacity: 1;
            }
        }
    }
    }
}


.nav-active{
    background: black;
    height: 100vh;
    .nav-links{
        transform: translateX(0);
        opacity: 1;
        height: 100vh;
        background: black;
        ul{
            margin-top: 50px;
        }
    }
    .nav-content{

        .burger{

            .line1 {
                stroke-dasharray: 90 207;
                stroke-dashoffset: -134;
                stroke-width: 3;
              }
              .line2 {
                stroke-dasharray: 1 60;
                stroke-dashoffset: -30;
                stroke-width: 3;
              }
              .line3 {
                stroke-dasharray: 90 207;
                stroke-dashoffset: -134;
                stroke-width: 3;
              }
            .line-1{
                transform: rotate(-45deg);
            }
            .line-2{
                opacity: 0;
            }
            .line-3{
                transform: rotate(45deg);
            }
        }
   
}
}

.loaded-nav{
    animation: 1.5s fadeNav ease-in-out forwards 1.2s;
}

@keyframes fadeNav{
    from{
        transform: translateY(-100%);
    }
    to{
        transform: translateY(0);
    }
}

.nav-normal{
    animation: 1.2s fadeNav ease-in-out forwards;
    background: black;
    .nav-content{
        margin: 5px 0px;
    }
}


.scrolled{
    nav{
        background: rgba(0, 0, 0, .9);
        .nav-content{
            margin: 12px 0px;
        }
    }
}
.notscrolled{
    nav{
        background: transparent;
        .nav-content{
            margin: 45px 0px;
        }
    }
}