.gallery-section{
    width: 90vw;
    margin: 0 auto;
    margin-top: 20vh;
    margin-bottom: 20vh;
    .mobile-gallery{
        display: none;
    }
    .container-headline{
        display: flex;
        justify-content: center;
        margin-bottom: 90px;
    }
    .item-overlay{
        overflow: hidden;
        height: 100%;
        &:hover{
            .overlay{
                opacity: .8;
            }
            img{
                -webkit-transform: scale(1.03);
                -moz-transform: scale(1.03);
                -ms-transform: scale(1.03);
                -o-transform: scale(1.03);
                transform: scale(1.03);
            }
        }
        position: relative;
        img{
            width: 100%;
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -ms-transform: scale(1);
            -o-transform: scale(1);
            transform: scale(1);
            -webkit-transition: all 0.3s  ease;
            -moz-transition: all 0.3s  ease;
            -ms-transition: all 0.3s  ease;
            -o-transition: all 0.3s  ease;
            transition: all 0.3s ease;

        }
            .overlay {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: 100%;
                opacity: 0;
                transition: .5s ease;
                .text {
                    color: rgb(129, 124, 124);
                    font-size: 20px;
                    font-family: 'Libre Baskerville';
                    font-size: 70px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    text-align: center;
                  }
              }
              
    }
    .desktop-gallery{
    .gallery-grid{
        width: 70vw;
    margin: 0 auto;
        img{
            width: 101%;
            height: 101%;
        }
        .item-overlay:nth-child(1) img{
            height: 100%;
        }
        .sub-grid-1{
            grid-template-columns: 41.5% 17% 41.5%;
            
        }
        .sub-grid-2{
            grid-template-columns: 30% 70%;
        }
        .sub-grid-3{
           grid-template-columns: repeat(3, 1fr);
           .item{
               .first{
                    height: 40% !important;
               }
               .second{
                height: 60% !important;
                }
           }
        }
        .sub-grid{
            display: grid;
            
            img{
                display: block;
            }
           
           

            
        }
        .last-img{
            height: 100%;
        }

    }
}
}


.css-10yc0wa{
    z-index: 99999999999 !important;
}

.SRLThumbnailsButton, .SRLAutoplayButton, .SRLZoomOutButton{
    display: none !important;
}

.SRLExpandButton{
    opacity: 0 !important;
}

.SRLDownloadButton{
    width: auto !important;
    margin-right: 20px !important;
&::before{
    content: 'Download';
    border: 1px solid white;
    padding: 7px 25px;
    color: white;
    font-family: 'Montserrat';
    font-size: 23px;
    background: rgba(30,30,36,0.8);

}
}
.css-1x4ynwp div{
    display: none !important;
}

.css-oizohe{
    margin-top: 90px;
}
.css-185typf{
    margin-top: 15px !important;
}
.css-138pcbv{
    margin-top: 10px;
}

.SRLImage{
    &::after{
        content: "tsst"
    }
}
#SRLLightbox{
    z-index: 99999999999 !important;
}