
.no-webp{
    .animation-bi{
        height: auto;
        width: 100vw;
        background: linear-gradient(0deg, #000000 5.89%, rgba(0, 0, 0, 0) 44.05%), url(../Assets/img/bio.jpg);
        background-position-x: center;
        background-size: cover;
        margin-top: 15vh;
        transition: all .9s;
    
        .animation-bio{
        height: 100%;
        min-height: 1000px;
        width: 100vw;
        transition: all .9s;
        position: relative;
        }
    
    
    }
.bio-f{
    height: auto;
    width: 100vw;
    background: linear-gradient(0deg, #000000 5.89%, rgba(0, 0, 0, 0) 44.05%), url(../Assets/img/bio.jpg);
    background-position-x: center;
    background-size: cover;
}
}
.webp{
    .animation-bi{
        height: auto;
        width: 100vw;
        background: linear-gradient(0deg, #000000 5.89%, rgba(0, 0, 0, 0) 44.05%), url(../Assets/img/bio.webp);
        background-position-x: center;
        background-size: cover;
        margin-top: 15vh;
        transition: all .9s;
    
        .animation-bio{
        height: 100%;
        min-height: 1000px;
        width: 100vw;
        transition: all .9s;
        position: relative;
        }
    
    
    }
    .bio-f{
        height: auto;
        width: 100vw;
        background: linear-gradient(0deg, #000000 5.89%, rgba(0, 0, 0, 0) 44.05%), url(../Assets/img/bio.webp);
        background-position-x: center;
        background-size: cover;
    }
}
.bio-s{
    height: auto;
    width: 100vw;
    background: linear-gradient(0deg, #000000 5.89%, rgba(0, 0, 0, 0) 44.05%), url(../Assets/img/bio.jpg);
    background-position-x: center;
    background-size: cover;
}
.trigger{

.textbio{
    height: auto;
    width: 85vw;
    margin: 0 auto;
    color: white;
    opacity: 0;
    .inside-bio{
        padding-top: 140px; 
        
        .bio-text {
        column-count: 2;
        column-gap: 50px;
        line-height: 2.2;
        margin-top: 25px
    }
    }


}
}
.animation-bio, .textbio{
    transition: all 1.6s;
}
.animation-bio{
    background: transparent;
    opacity: 0;
    position: relative;
}
.textbio{
    position: relative;
    top: -50px;
    opacity: 0;
}

  .animation-bio[data-emergence=visible]{
    animation: 1.6s fadeHero forwards .2s;
  }

  .textbio[data-emergence=visible]{
    animation: 1.2s fadeHText forwards .2s;
  }





  @keyframes fadeHero{
      from{
          background: transparent;
          opacity: 0;
      }
      to{
        background:  linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 64.11%), rgba(0, 0, 0, .4);
        opacity: 1;
      }
  }

  @keyframes fadeHText{
    from{
        opacity: 0;
        top: -50px;
    }
    to{
      opacity: 1;
      top: 0px;

    }
}