.social-section{
    margin-top: 20vh;
    h2{
        margin-bottom: 30px;
    }
    .logo-section{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin: 0 auto;
        width: 30%;
        margin-top: 40px;
        margin-bottom: 70px;
        align-items: center;
        gap: 35px;
    }
}