.contact-section{
    width: 100vw;
    height: 100vh;
    min-height: 900px;
    margin-top: 25vh;
 
    display: flex;
    justify-content: flex-end;
    .container-contact{
        margin-right: 7.5vw;
        margin-top: 10%;
    }
        text-align: center;
        h2{
            color: white;
        }
       


                  
          .contact-item {
            margin-top: 10px;
            svg{
                width: auto;
                height: 35px;

            }
 
            .agency-box{
                position: relative;
                display: flex;
                    
                    p{
                        color: white;
                        text-align: center;

                    }
                    svg{
                        margin-left: -4px;
                        width: 39px;
                        display: none;

                    }

                    a {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        text-decoration: none;
                        color: white;
                        transition: .3s;
                        &:hover {
                            opacity: 1;
                        }
                        
                    }
            }
            .hover-box {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                position: relative;
                a:nth-child(1){
                    position: relative;
                    text-decoration: none;
                }
                svg{
                    
                }
                .center-link {
                

                    a {
                        width: auto;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 22px;
                        opacity: 1;
                        text-decoration: none;
                        color: white;
                        transition: .3s;
                        margin-left: 15px;
                        
                        margin-top: -3px;

                    
                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }
          
}


.contact-grid{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    gap: 20px;
}
.no-webp{
.contact-section{
    background:linear-gradient(260.26deg, #000000 1.28%, rgba(0, 0, 0, 0) 63.16%), url(../Assets/img/contact.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}
}

.webp{
    .contact-section{
        background:linear-gradient(260.26deg, #000000 1.28%, rgba(0, 0, 0, 0) 63.16%), url(../Assets/img/contact.webp);
        background-size: cover;
        background-repeat: no-repeat;
    }
    }